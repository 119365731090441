:root {
  --font: "Montserrat", sans-serif;
  --font2: "Merriweather", serif;
  --font3: "Lato", sans-serif;
  --font4: "Playfair Display", serif;
  --font5: "Raleway", sans-serif;
  --font6: "Open Sans", sans-serif;
  --font7: "Waterfall", cursive;
  --font8: "Boldonse", system-ui;
  --negro: #222;
  --gris: #e2e2e2;
  --azul1: #154580;
  --naranja: #ffb74d;
  --paleta1: rgba(167, 230, 255, 0.5);
  --paleta2: rgba(53, 114, 239, 0.95);
  --textos1: #050c9c;
  --textos2: #3572ef;
  --textos3: #a7e6ff;
  --botones1: #3572ef;
  --botones2: #3abef9;
  --hover1: #050c9c;
  --hover2: #3572ef;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Lato", "Open Sans", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
