.contactForm {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 600px;
  margin: 0 auto;
  background: #f8f9ff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.contactForm label {
  text-align: left;
  font-weight: bold;
  color: #4d55cc;
}

.contactForm input,
.contactForm textarea {
  width: calc(100% - 1rem);
  padding: 10px;
  border: 1px solid #b5a8d5;
  border-radius: 5px;
  font-size: 1rem;
  margin: 0 auto;
}

.submitButton {
  background-color: #7a73d1;
  color: white;
  width: 100%;
  padding: 10px;
  border: none;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.submitButton:disabled {
  background-color: #888;
  cursor: not-allowed;
}

.submitButton:hover {
  background-color: #4d55cc;
}
