.animatedCard {
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.hidden {
  opacity: 0;
  transform: translateX(100px);
}

.visible {
    opacity: 1;
    transform: translateX(0);
}

.slideFromLeft {
  animation: slideInFromLeft 1s ease-out forwards;
}

.slideFromRight {
  animation: slideInFromRight 1s ease-out forwards;
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-30vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  from {
    transform: translateX(30vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
