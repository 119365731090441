.footerContainer {
  background: linear-gradient(to top, #4d55cc, #e7e9f9);
  padding: 50px 0 0 0;
  line-height: 1.6;
  font-size: 14px;
  margin-top: 5rem;
}

.footerContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  gap: 30px;
}

.footerSection {
  flex: 1;
  min-width: 250px;
  margin-bottom: 30px;
}

/* Sección Logo y Descripción */
.logoContainer {
  display: flex;
  align-items: center;
  margin-top: 2rem;
}

.footerLogo {
  
  margin-right: 1rem;
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
}

.siteName {
  display: flex;
  align-items: self-end;
  font-size: 3rem;
  color: #211c84;
  font-family: var(--font8);
  font-weight: 400;
  font-style: normal;
  margin: 0.1rem;
  letter-spacing: -0.3rem;
  transform: scaleY(1.3);
}

.subName {
  font-size: 0.9rem;
  color: #211c84;
  font-style: normal;
  margin-left: 0.2rem;
  letter-spacing: normal;
  transform: scaleY(0.8);
  margin-bottom: 0.8rem;
}

.footerSectionAbout .footerLogo {
  max-width: 280px;
  margin-bottom: 20px;
  display: block;
}

.footerSectionAbout .aboutText {
  color: #211c84;
}

/* Títulos de Sección */
.footerHeading {
  color: #ffffff;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
  position: relative;
  padding-bottom: 10px;
}

.footerHeading::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 40px;
  height: 2px;
  background-color: #211c84;
}

/* Sección Enlaces */
.footerSectionLinks ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footerSectionLinks ul li {
  margin-bottom: 10px;
}

.footerSectionLinks ul li a {
  color: #211c84;
  text-decoration: none;
  transition: color 0.3s ease, padding-left 0.3s ease;
}

.footerSectionLinks ul li a:hover {
  color: #ffffff;
  padding-left: 5px;
}

.footerLiH4 {
  margin: 0;
  margin-bottom: 10px;
  font-weight: normal;
  /* color: #211c84; */
  text-decoration: none;
}

.tab {
  margin-left: 40px;
  list-style-type: disc;
}

/* Sección Contacto */
.footerSectionContact .contactItem {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.footerSectionContact .contactIcon {
  color: #211c84;
  margin-right: 10px;
  font-size: 16px;
  width: 20px;
  text-align: center;
  flex-shrink: 0;
  margin-top: 3px;
}

.footerSectionContact span,
.footerSectionContact a {
  color: #211c84;
  text-decoration: none;
}

.footerSectionContact a:hover {
  color: #ffffff;
}

/* Iconos Sociales */
.socialIcon {
  margin-top: 25px;
}

.socialIcon a {
  color: #211c84;
  font-size: 20px;
  margin-right: 15px;
  text-decoration: none;
  transition: color 0.3s ease;
}

.socialIcon a:hover {
  color: #007bff;
  text-shadow: #211c84 1px 1px 1px;
}

.socialIcon a:last-child {
  margin-right: 0;
}

/* Sección Inferior - Copyright */
.footerBottom {
  background-color: #111;
  text-align: center;
  margin-top: 30px;
  padding: 2vh;
  font-size: 0.8rem;
  bottom: 0;
}

.footerBottom p {
  color: #ccc;
}

/* Tablets y dispositivos más pequeños */
@media (max-width: 768px) {
  .footerContent {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footerSection {
    min-width: 100%;
    margin-bottom: 40px;
  }

  .footerSectionAbout {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    align-items: center;
  }

  .footerSectionAbout .footerLogo {
    margin-left: 0;
    margin-right: 0;
    height: 4rem;
  }

  .siteName {
    font-size: 2rem;
  }

  .footerHeading::after {
    left: 50%;
    transform: translateX(-50%);
  }

  .footerSectionLinks {
    margin-bottom: 0;
  }

  .footerSectionContact .contactItem {
    justify-content: center;
    text-align: left;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .socialIcons {
    text-align: center;
  }
}

/* Móviles pequeños */
@media (max-width: 480px) {
  .footerContainer {
    padding-top: 30px;
  }

  .footerHeading {
    font-size: 16px;
  }

  .footerSection {
    margin-bottom: 30px;
  }

  .footerBottom p {
    font-size: 12px;
  }

  .socialIcons a {
    font-size: 18px;
    margin-right: 10px;
  }
}
