.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.title {
  font-size: 2rem;
  color: #211c84;
  text-align: center;
  margin-bottom: 40px;
}

.description {
  font-size: 1.2rem;
  color: #4d55cc;
  margin-bottom: 30px;
}

/**/

.row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.column {
  flex: 1 1 calc(33.333% - 20px);
  box-sizing: border-box;
  min-width: 280px;
}

.card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  /* min-height: 14rem; */
  background-color: #fff;
  transition: transform 0.3s;
}

.card:hover {
  transform: scale(1.05);
}

.cardTitle {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.cardDescription {
  font-size: 1rem;
  color: #555;
  margin-bottom: 15px;
}

.button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
}

.button:hover {
  background-color: #0056b3;
}

.details {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.detailsActiveContainer {
  border-left: 4px solid #007bff;
  text-align: initial;
}

.detailsActive {
  max-height: 18rem;
  margin-top: 15px;
  padding: 10px;
  background-color: #f8f9fa;
  font-size: 0.9rem;
  color: #333;
}

.detailsActive ul {
  margin-left: 0.1rem;
}

.detailsActive li {
  margin-left: 10px;
  text-align: left;
}
