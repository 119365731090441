/* Animación para deslizar desde abajo */
@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Animación para deslizar desde la izquierda */
@keyframes slideInFromLeft {
  from {
    transform: translateX(-90vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Animación para deslizar desde la derecha */
@keyframes slideInFromRight {
  from {
    transform: translateX(90vw);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.slideFromLeft {
  animation: slideInFromLeft 1s ease-out forwards;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.slideFromRight {
  animation: slideInFromRight 1s ease-out forwards;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.slideFromBottom {
  animation: slideInFromBottom 1s ease-out forwards;
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.hidden {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  color: #333;
}

.hero {
  max-width: 1080px;
  margin: 0 auto;
}

.title {
  font-size: 2rem;
  color: #211c84;
  text-align: center;
  margin-bottom: 20px;
}

.nav {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.navButton {
  background-color: #3abef9;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}

.navButton:hover {
  background-color: #3572ef;
}

.active {
  background-color: #050c9c;
}

.categoryTitle {
  font-size: 2rem;
  color: #4d55cc;
  border-bottom: 2px solid #211c84;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.content {
  text-align: left;
  max-width: 900px;
  margin: 0 auto;
}

.faqItem {
  margin-bottom: 20px;
}

.details {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.details[open] {
  background-color: #e6e6ff;
}

.question {
  font-size: 1.2rem;
  font-weight: bold;
  color: #211c84;
  cursor: pointer;
}

.answer {
  font-size: 1rem;
  color: #555;
  margin-top: 10px;
}
