.header {
  /*background-color: #4D55CC;*/
  font-family: var(--font3);
  background: linear-gradient(to bottom, #4d55cc, #e7e9f9);
  padding: 0px 20px;
  height: 12rem;
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoImg {
  height: 8rem;
  margin-right: 1rem;
  /* margin-top: 0.2rem;
  margin-bottom: 0.2rem; */
}

.siteName {
  display: flex;
  align-items: self-end;
  font-size: 3rem;
  color: #211c84;
  font-family: var(--font8);
  font-weight: 400;
  font-style: normal;
  margin: 0.1rem;
  letter-spacing: -0.3rem;
  transform: scaleY(1.3);
}

.subName {
  font-size: 0.9rem;
  color: #211c84;
  font-style: normal;
  margin-left: 0.2rem;
  letter-spacing: normal;
  transform: scaleY(0.8);
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  .logoImg {
    height: 4rem;
  }

  .siteName {
    font-size: 2rem;
  }

  .subName {
    font-size: 0.8rem;
  }
}
