.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 1000;
}

/* Estilos del menú principal */
.navLinks {
  list-style: none;
  display: flex;
  gap: 15px;
  font-weight: bold;
}

.navLinks li a,
.navLinks span {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px;
  transition: color 0.3s ease-in-out;
}

.navLinks li a:hover,
.navLinks span:hover {
  color: #4d55cc;
}

/* Dropdown (Submenú) */
.dropdown {
  position: relative;
}

.dropdownTitle {
  cursor: pointer;
  padding: 5px 10px;
}

.submenu {
  display: none;
  position: absolute;
  background: none;
  list-style: none;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  min-width: 120px;
  transition: all 0.5s ease-in-out;
}

.submenu li {
  padding: 5px 0;
}

.showSubmenu {
  display: block !important;
}

.hasSubmenu:hover .submenu {
  display: block;
}

/* Estilos del botón del menú hamburguesa */
.menuButton {
  display: none;
  background: none;
  border: none;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
}

@media (max-width: 1080px) {
  .menuButton {
    display: block;
  }

  .navLinks {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: var(--paleta2);
    width: 100%;
    text-align: center;
    padding: 10px 0;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .navLinks.showMenu {
    display: flex;
  }

  .submenu {
    position: absolute;
    right: 6rem;
    top: 0;
    background-color: #4d55cc;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }

  .submenu a {
    padding: 10px 20px;
    color: black;
    display: block;
  }

  .navLinks li a:hover,
  .navLinks span:hover {
    color: #050c9c;
  }
}
