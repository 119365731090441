.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.title {
  font-size: 2rem;
  color: #211c84;
  margin-bottom: 20px;
  text-align: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  background-color: #7a73d1;
  color: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
}

.image {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.cardTitle {
  font-size: 1.5rem;
  margin: 10px 0;
}

.description {
  font-size: 1rem;
  margin-bottom: 15px;
  max-height: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
}

.link {
  color: #b5a8d5;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}
