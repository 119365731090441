.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  text-align: center;
  margin: 0 auto;
  padding: 40px 20px;
  max-width: 600px;
}

.title {
  font-size: 2rem;
  color: #211c84;
  margin-bottom: 20px;
}

.image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.context {
  font-size: 1.2rem;
  color: #4d55cc;
}

.returnButton {
  padding: 10px 20px;
  background-color: #9575cd;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s;
}

.returnButton:hover {
  background-color: #7e57c2;
}

.responsiveImage {
  width: 100%;
  height: auto;
  max-width: 800px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
