.mediaContainer {
  display: flex;
  justify-content: center;
}

.title {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 2.5rem;
  margin: 2rem;
}

.items {
  display: inline-block;
  font-size: 1.5rem;
}

.items a {
  text-decoration: none;
  color: #211c84;
  transition: color 0.3s ease-in-out;
}

.items a:hover {
  color: #7a73d1;
}

.redesSociales__img {
  font-size: 2rem;
}
