.contactContainer {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
}

.contactTitle {
  font-size: 2rem;
  color: #211c84;
  margin-bottom: 20px;
}

.contactDescription {
  font-size: 1.2rem;
  color: #4d55cc;
  margin-bottom: 30px;
}

.mapContainer {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

iframe {
  width: 100%;
  max-width: 700px;
  height: 350px;
  border-radius: 10px;
  border: none;
}

/* Responsivo */
@media (max-width: 768px) {
  .contactForm {
    width: 90%;
  }

  iframe {
    height: 350px;
  }
}
