@keyframes slideIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.hidden {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.visible {
  opacity: 1;
  transform: translateY(0);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  text-align: center;
  color: white;
}

.hero {
  max-width: 1080px;
  margin: 0 auto;
}

.title {
  font-size: 2rem;
  color: #211c84;
  margin-bottom: 10px;
}

.description {
  flex: 1;
  font-size: 1.1rem;
  color: var(--textos2);
  text-align: justify;
  max-width: 600px;
}

.hero p {
  font-size: 1.2rem;
  color: #4d55cc;
  margin-bottom: 30px;
}

.features {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 40px;
}

.featureCard {
  background-color: #fff;
  border: 1px solid var(--gris);
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
}

.featureCard:nth-child(1) {
  animation-delay: 0.5s;
}

.featureCard:nth-child(2) {
  animation-delay: 0.7s;
}

.featureCard:nth-child(3) {
  animation-delay: 0.9s;
}

.featureCard h2 {
  color: #4d55cc;
  font-size: 1.5rem;
}

.featureCard p {
  font-size: 1rem;
  color: var(--textos2);
}

.featureCard.visible {
  opacity: 1;
  transform: translateY(0);
}

.contentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  max-width: 1200px;
  margin: 8rem 0 8rem 0;
  flex-wrap: wrap;
}

.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  max-width: 40rem;
}

.responsiveImage {
  width: 100%;
  height: auto;
  max-width: 30rem;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .contentContainer {
    flex-direction: column;
    text-align: center;
    margin: 1.1rem 0 1.1rem 0;
  }

  .description {
    text-align: center;
    margin-bottom: 1.1rem;
  }

  .features {
    flex-direction: column;
  }

  .contentSection {
    margin-bottom: 1.1rem;
  }
}
